import React, { SyntheticEvent, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import scannedMediaObjectRowStyle from "./scannedMediaObjectRowStyle";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import LegalbirdAutoComplete from "../AutoComplete/LegalbirdAutoComplete";
import _ from "lodash";
import { ALGOLIA_INDEX } from "../../config/_entrypoint";
import ApiClient from "../../services/ApiClient";
import { Delete, FeedbackOutlined, FileDownloadOutlined } from "@mui/icons-material";
import { getCaseLink } from "../../services/Product/ProductService";
import { Link } from "react-router-dom";
import { useActionIdentifier } from "../../provider/ActionIdentifierProvider";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ButtonLoading from "../Button/ButtonLoading";
import SearchBar from "../Algolia/SearchBar";
import CaseHit from "../Case/CaseEntityPicker/Hit/CaseHit";
import LabeledSelect from "../Select/LabeledSelect";
import { userHasRole } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { MediaObject, ScanMetaData } from "../../types/MediaObject";
import { CaseHit as CaseHitType } from "../Case/CaseEntityPicker/Hit/hitType";
import { AutoCompleteOptionWithValue } from "../../types/AutoCompleteOptionWithValue";
import { useDocumentClassLabelAi } from "../../provider/DocumentClassLabelAiProvider";
import DisplayableMediaObject from "../MediaObject/DisplayableMediaObject";

const useStyles = makeStyles(scannedMediaObjectRowStyle);

type ScannedMediaObjectRowProps = {
  mediaObject: MediaObject;
  refreshList: Function;
};

export default function ScannedMediaObjectRow({ mediaObject, refreshList }: ScannedMediaObjectRowProps) {
  const classes = useStyles();
  const currentUser = useCurrentUser();

  const {
    getDocumentClassLabelsAiByProductClass,
    prepareAndSortLabels,
    getPreparedDocumentClassLabelsAiByProductAndLabelValue,
    isLoading: isLoadingDocumentClassLabels,
  } = useDocumentClassLabelAi();
  const [documentClassLabelAi, setDocumentClassLabelAi] = useState<AutoCompleteOptionWithValue | null>(null);
  const [documentClassLabelAiOptions, setDocumentClassLabelAiOptions] = useState<any[]>([]);
  const [isLabelPrefilledFromDatabase, setIsLabelPrefilledFromDatabase] = useState(true);

  const {
    getActionIdentifiersByLabel,
    prepareAndSortActionIdentifiers,
    getPreparedActionIdentifierOptionByLabelAndIdentifier,
    isLoading: isLoadingActionIdentifiers,
  } = useActionIdentifier();
  const [actionIdentifier, setActionIdentifier] = useState<AutoCompleteOptionWithValue | null>(null);
  const [actionIdentifierOptions, setActionIdentifierOptions] = useState<any[]>([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeField, setActiveField] = useState("");
  const [fileDescription, setFileDescription] = useState(mediaObject.description);
  const { productId, productType: productClassName, caseLabel, reference } = mediaObject.scanMetaData!;

  // On first load fetch the label/identifier data based on mediaObject
  useEffect(() => {
    if (isLoadingDocumentClassLabels || isLoadingActionIdentifiers) {
      return;
    }
    const currentMediaObjectLabel = mediaObject.documentClassLabelAi || mediaObject.scanMetaData?.labelPredicted;
    const currentMediaObjectProductClassName = mediaObject.product || mediaObject.scanMetaData?.productType;
    if (!!currentMediaObjectProductClassName) {
      let productSpecificLabels = prepareAndSortLabels(
        getDocumentClassLabelsAiByProductClass(currentMediaObjectProductClassName)
      );
      setDocumentClassLabelAiOptions(productSpecificLabels);
      if (!currentMediaObjectLabel) {
        return;
      }
      let savedLabel = getPreparedDocumentClassLabelsAiByProductAndLabelValue(
        currentMediaObjectProductClassName,
        currentMediaObjectLabel
      );
      setDocumentClassLabelAi(savedLabel);
      let labelSpecificIdentifiers = prepareAndSortActionIdentifiers(
        getActionIdentifiersByLabel(currentMediaObjectLabel)
      );
      setActionIdentifierOptions(labelSpecificIdentifiers);

      const currentMediaObjectIdentifier = mediaObject.actionIdentifier;
      if (!!currentMediaObjectIdentifier) {
        let savedIdentifier = getPreparedActionIdentifierOptionByLabelAndIdentifier(
          currentMediaObjectLabel,
          currentMediaObjectIdentifier
        );
        setActionIdentifier(savedIdentifier);
      } else {
        // If there is only one identifier for the label, set it as default, if there is no savedIdentifier
        if (labelSpecificIdentifiers.length === 1) {
          setActionIdentifier(labelSpecificIdentifiers[0]);
        } else {
          setActionIdentifier(null);
        }
      }
    }
  }, [isLoadingDocumentClassLabels, isLoadingActionIdentifiers]);

  // For label selection
  useEffect(() => {
    if (productClassName && !isLoadingDocumentClassLabels) {
      let productSpecificLabels = prepareAndSortLabels(getDocumentClassLabelsAiByProductClass(productClassName));
      setDocumentClassLabelAiOptions(productSpecificLabels);
      setIsLabelPrefilledFromDatabase(false);
    }
  }, [productClassName]);

  // For actionIdentifier selection
  useEffect(() => {
    if (documentClassLabelAi && !isLoadingActionIdentifiers) {
      let labelSpecificIdentifiers = prepareAndSortActionIdentifiers(
        getActionIdentifiersByLabel(documentClassLabelAi.value)
      );
      setActionIdentifierOptions(labelSpecificIdentifiers);
      if (isLabelPrefilledFromDatabase) {
        return;
      }
      if (labelSpecificIdentifiers.length === 1) {
        setActionIdentifier(labelSpecificIdentifiers[0]);
      } else {
        setActionIdentifier(null);
      }
    }
  }, [documentClassLabelAi]);

  const onCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    let scanMetaDataCopy = { ...mediaObject.scanMetaData! };
    scanMetaDataCopy.checkedDate = event.target.checked ? moment().format() : null;
    scanMetaDataCopy.checkedBy = event.target.checked ? currentUser["@id"] : null;
    await handleScanMetaDataSave(scanMetaDataCopy);
    refreshList();
    setIsLoading(false);
  };

  const onHitClick = async (
    { product, productId, reference, label }: CaseHitType,
    event?: SyntheticEvent<HTMLElement>
  ) => {
    if (!event) {
      return;
    }
    event.preventDefault();
    setIsLoading(true);
    let scanMetaDataCopy = { ...mediaObject.scanMetaData! };
    scanMetaDataCopy.productType = product;
    scanMetaDataCopy.productId = productId;
    scanMetaDataCopy.reference = reference;
    scanMetaDataCopy.caseLabel = label;
    await handleScanMetaDataSave(scanMetaDataCopy);
    refreshList();
    setIsLoading(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const resetActiveField = () => {
    setActiveField("");
  };

  const handleDeleteMediaObject = async () => {
    setIsLoading(true);
    await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({
        deleted: true,
      }),
    });
    refreshList();
    setIsLoading(false);
    setDeleteModalOpen(false);
  };

  const handleDocumentClassLabelAiValueChange = async (
    _e: React.SyntheticEvent,
    value: AutoCompleteOptionWithValue | null
  ) => {
    if (!value) {
      return;
    }
    setIsLoading(true);
    setIsLabelPrefilledFromDatabase(false);
    setDocumentClassLabelAi(value);

    let payload: Partial<MediaObject> = {
      documentClassLabelAi: value.value,
      actionIdentifier: null,
    };

    let labelSpecificIdentifiers = prepareAndSortActionIdentifiers(getActionIdentifiersByLabel(value.value));
    if (labelSpecificIdentifiers.length === 1) {
      payload.actionIdentifier = labelSpecificIdentifiers[0].value;
    }

    await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify(payload),
    });

    await refreshList();
    resetActiveField();
    setIsLoading(false);
  };

  const handleActionIdentifierValueChange = async (
    _e: React.SyntheticEvent,
    value: AutoCompleteOptionWithValue | null
  ) => {
    if (!value) {
      return;
    }
    setIsLoading(true);
    setActionIdentifier(value);

    let payload: Partial<MediaObject> = {
      actionIdentifier: value.value,
      documentClassLabelAi: documentClassLabelAi?.value,
    };

    if (value.value !== "NO_ACTION") {
      payload.description = "";
      setFileDescription("");
    }

    await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify(payload),
    });

    await refreshList();
    resetActiveField();
    setIsLoading(false);
  };

  const handleSaveDescription = async () => {
    setIsLoading(true);
    await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({
        description: fileDescription,
      }),
    });
    refreshList();
    resetActiveField();
    setIsLoading(false);
  };

  const handleScanMetaDataSave = async (scanMetaData: ScanMetaData) => {
    await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({
        scanMetaData: scanMetaData,
        documentClassLabelAi: documentClassLabelAi?.value,
        actionIdentifier: actionIdentifier?.value,
      }),
    });
  };

  const handleVisibilityAndCustomerInfoChange = async ({ target }: SelectChangeEvent) => {
    setIsLoading(true);
    let scanMetaDataCopy = { ...mediaObject.scanMetaData! };
    scanMetaDataCopy.visibilityAndCustomerInfo = target.value;
    await handleScanMetaDataSave(scanMetaDataCopy);
    await refreshList();
    resetActiveField();
    setIsLoading(false);
  };

  const handlePreventDefaultActionsChange = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    let scanMetaDataCopy = { ...mediaObject.scanMetaData! };
    scanMetaDataCopy.preventDefaultActions = target.checked;
    await handleScanMetaDataSave(scanMetaDataCopy);
    await refreshList();
    resetActiveField();
    setIsLoading(false);
  };

  const handleElectronicFileFolderPathChange = ({ target }: SelectChangeEvent) => {
    setIsLoading(true);
    ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({
        electronicFileFolderPath: target.value,
      }),
    })
      .then(() => {
        refreshList();
      })
      .finally(() => {
        resetActiveField();
        setIsLoading(false);
      });
  };

  const isUnlabeledDocument = actionIdentifier?.value === "NO_ACTION";

  const isCheckable =
    !!productId &&
    !isLoading &&
    !(isUnlabeledDocument && _.isEmpty(mediaObject.description)) &&
    !(isUnlabeledDocument && ["blank", null].includes(mediaObject.electronicFileFolderPath)) &&
    (!!mediaObject.actionIdentifier || !!mediaObject.scanMetaData?.labelPredicted) &&
    (!isUnlabeledDocument || mediaObject.scanMetaData?.visibilityAndCustomerInfo);

  if (isLoadingActionIdentifiers) {
    return null;
  }

  const isChecked = !!mediaObject.scanMetaData?.checkedDate;

  if (isLoadingDocumentClassLabels || isLoadingActionIdentifiers) {
    return null;
  }

  return (
    <>
      <TableRow sx={scannedMediaObjectRowStyle.borderLess}>
        <TableCell>
          <div>{moment(mediaObject.created).format("DD.MM.YYYY")}</div>
          <div>{moment(mediaObject.created).format("HH:mm")} Uhr</div>
        </TableCell>
        <TableCell>
          <DisplayableMediaObject mediaObject={mediaObject} />
        </TableCell>
        <TableCell>
          {!!productId ? (
            <Link className={classes.link} to={getCaseLink({ productClassName, id: productId })} target={"_blank"}>
              {!!caseLabel && !!reference ? caseLabel + " - " + reference : !!caseLabel ? caseLabel : "Zum Fall"}
            </Link>
          ) : (
            "Unzugeordnet"
          )}
        </TableCell>
        <TableCell>
          <SearchBar
            searchConfig={{
              indexName: ALGOLIA_INDEX,
            }}
            resultListConfig={{
              itemSize: 330,
              width: "100%",
            }}
            HitComponent={CaseHit}
            onHitClick={onHitClick}
            disabled={isLoading || !["search", ""].includes(activeField) || isChecked}
          />
        </TableCell>
        <TableCell>
          <LegalbirdAutoComplete
            disabled={!productId || isLoading || !["documentClassLabelAi", ""].includes(activeField) || isChecked}
            setValue={handleDocumentClassLabelAiValueChange}
            value={documentClassLabelAi}
            options={documentClassLabelAiOptions}
            label={"Dokumentenart"}
            onFocus={() => setActiveField("documentClassLabelAi")}
            onBlur={resetActiveField}
            isOptionEqualToValue={(option: AutoCompleteOptionWithValue, value: AutoCompleteOptionWithValue) =>
              option.value === value.value
            }
          />
        </TableCell>
        <TableCell>
          <LegalbirdAutoComplete
            disabled={!productId || isLoading || !["actionIdentifier", ""].includes(activeField) || isChecked}
            setValue={handleActionIdentifierValueChange}
            value={actionIdentifier}
            options={actionIdentifierOptions}
            label={"Aktionskenner"}
            onFocus={() => setActiveField("actionIdentifier")}
            onBlur={resetActiveField}
            isOptionEqualToValue={(option: AutoCompleteOptionWithValue, value: AutoCompleteOptionWithValue) =>
              option.value === value.value
            }
          />
        </TableCell>
        <TableCell>
          <Checkbox disabled={!isCheckable} onChange={onCheckboxChange} checked={isChecked} />
        </TableCell>
      </TableRow>
      <TableRow sx={scannedMediaObjectRowStyle.additionalRow}>
        <TableCell>
          <IconButton
            component="a"
            download={mediaObject.originalName}
            href={mediaObject.downloadUrl}
            disabled={isLoading}
            title={"Datei herunterladen"}
          >
            <FileDownloadOutlined />
          </IconButton>
          {mediaObject.fieldname === "bea" && !userHasRole(currentUser, Roles.admin) ? (
            "beA"
          ) : (
            <>
              <IconButton onClick={handleOpenDeleteModal} disabled={isLoading || isChecked}>
                <Delete />
              </IconButton>
              <LegalbirdIoModal
                handleClose={() => setDeleteModalOpen(false)}
                open={deleteModalOpen}
                title={"Dokument löschen"}
                submitButton={
                  <ButtonLoading onClick={handleDeleteMediaObject} isLoading={isLoading} variant={"contained"}>
                    Dokument löschen
                  </ButtonLoading>
                }
              >
                Durch den Klick auf "Dokument löschen" wird das Dokument dauerhaft gelöscht. Grund hierfür können
                beispielsweise falsch eingescannte oder nicht lesbare Dokumente sein.
              </LegalbirdIoModal>
            </>
          )}
          {mediaObject.scanMetaData?.isFamilyNameInText === false && (
            <Tooltip
              title="Automatische Fallzuordnung bitte genau prüfen, da Name des Mandanten im Dokument nicht erkannt wurde"
              placement={"top"}
            >
              <FeedbackOutlined sx={{ color: "#db1919", verticalAlign: "middle", margin: "0.5rem" }} />
            </Tooltip>
          )}
        </TableCell>
        {isUnlabeledDocument ? (
          <>
            <TableCell colSpan={2}>
              <TextField
                margin="none"
                label={"Beschreibung"}
                value={fileDescription}
                onChange={({ target }) => setFileDescription(target.value)}
                onBlur={handleSaveDescription}
                disabled={isLoading || !["description", ""].includes(activeField) || isChecked}
                onFocus={() => setActiveField("description")}
              />
            </TableCell>
            <TableCell colSpan={2}>
              <LabeledSelect
                label={"Anzeige & Info an Mandantschaft"}
                value={mediaObject.scanMetaData?.visibilityAndCustomerInfo || "blank"}
                onChange={handleVisibilityAndCustomerInfoChange}
                disabled={isLoading || !["visibilityAndCustomerInfo", ""].includes(activeField) || isChecked}
                onFocus={() => setActiveField("visibilityAndCustomerInfo")}
                onBlur={resetActiveField}
              >
                <MenuItem value={"blank"} disabled>
                  {" "}
                </MenuItem>
                <MenuItem value={"yes"}>Ja</MenuItem>
                <MenuItem value={"no"}>Nein</MenuItem>
              </LabeledSelect>
            </TableCell>
            <TableCell>
              <LabeledSelect
                label={"Ablage in Ordner"}
                value={mediaObject.electronicFileFolderPath || "blank"}
                onChange={handleElectronicFileFolderPathChange}
                disabled={isLoading || !["folder", ""].includes(activeField) || isChecked}
                onFocus={() => setActiveField("folder")}
                onBlur={resetActiveField}
              >
                <MenuItem value={"blank"} disabled>
                  {" "}
                </MenuItem>
                <MenuItem value={"Mandantschaft"}>Mandantschaft</MenuItem>
                <MenuItem value={"Gerichtliches Verfahren"}>Gerichtliches Verfahren</MenuItem>
                <MenuItem value={"Außergerichtliches Verfahren"}>Außergerichtliches Verfahren</MenuItem>
                <MenuItem value={"RSV"}>RSV</MenuItem>
                <MenuItem value={"ToDo"}>{"ToDo"}</MenuItem>
                <MenuItem value={"Rechnungen"}>Rechnungen</MenuItem>
                <MenuItem value={"Sonstiges"}>Sonstiges</MenuItem>
              </LabeledSelect>
            </TableCell>
          </>
        ) : (
          <TableCell colSpan={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mediaObject.scanMetaData?.preventDefaultActions || false}
                  onChange={handlePreventDefaultActionsChange}
                  disabled={isLoading || !["preventDefaultActions", ""].includes(activeField) || isChecked}
                  onFocus={() => setActiveField("preventDefaultActions")}
                  onBlur={resetActiveField}
                />
              }
              label={"Keine Folge-Aktionen anstoßen (Dokument liegt doppelt vor, oder aus anderen Gründen)"}
            />
          </TableCell>
        )}
      </TableRow>
    </>
  );
}
